import axios from "axios";

export const GET_TEAMS = "GET_TEAMS";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_FAIL = "GET_TEAMS_FAIL";

export function getTeams() {
  return (dispatch) => {
    dispatch({ type: GET_TEAMS });
    axios
      .get(`${process.env.REACT_APP_API_PATH}/teams`)
      .then((res) => {
        dispatch({
          type: GET_TEAMS_SUCCESS,
          data: res.data,
        });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
        dispatch({ type: GET_TEAMS_FAIL });
      });
  };
}

export function createTeam(teamName) {
  return () => {
    axios
      .post(
        `${process.env.REACT_APP_API_PATH}/teams?name=${teamName}`,
        {}, // empty body object
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("sketch-token"),
          },
        }
      )
      .then(() => {
        alert("Team Created Successfully!");
        window.location.reload(false);
      })
      .catch((err) => {
        alert(
          "ERROR: " + err.response?.data?.detail || "Failed to create team"
        );
        console.log(err);
      });
  };
}
