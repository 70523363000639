import React, { useEffect } from "react";
import "./App.scss";
import { Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import Header from "./component/Header";
import Match from "./pages/Match";
import MatchCreate from "./pages/MatchCreate";
import Login from "./pages/Login";
import Team from "./pages/Team";
import { validateToken } from "./store/actions/login";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Player from "./pages/Player";
import Post from "./pages/Post";
import User from "./pages/User";
import Leagues from "./pages/Leagues";
import TeamCreate from "./component/TeamCreate";

function App() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { isLoggedIn } = useSelector((store) => store.loginReducer);

  useEffect(() => {
    if (isLoggedIn === false) {
      navigate(`/login`);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    dispatch(validateToken());
  }, []);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="" element={<Main />} />
        <Route path="matches/*" element={<Match />} />
        <Route path="new-match" element={<MatchCreate />} />
        <Route path="players/*" element={<Player />} />
        <Route path="teams/*" element={<Team />} />
        <Route path="teams/create" element={<TeamCreate />} />
        <Route path="leagues" element={<Leagues />} />
        <Route path="posts/*" element={<Post />} />
        <Route path="users/*" element={<User />} />
        <Route path="login" element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
