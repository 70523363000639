import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { createTeam } from "../store/actions/team";
import "./TeamCreate.scss";

const TeamCreate = () => {
  const dispatch = useDispatch();
  const [teamName, setTeamName] = useState("");

  const handleTeamNameChange = (e) => {
    setTeamName(e.target.value);
  };

  const handleCreateTeam = () => {
    if (!teamName.trim()) {
      alert("Please enter a team name");
      return;
    }
    dispatch(createTeam(teamName));
    setTeamName("");
  };

  return (
    <div className="team-create-wrapper">
      <h2>Create New Team</h2>
      <div className="input-wrapper">
        <TextField
          id="team-name-text-field"
          label="Team Name"
          variant="outlined"
          sx={{ margin: "0.5rem" }}
          required={true}
          value={teamName}
          onChange={handleTeamNameChange}
        />
        <Button
          disabled={!teamName.trim()}
          variant="outlined"
          color="primary"
          onClick={handleCreateTeam}
          sx={{ margin: "0.5rem" }}
        >
          Create Team
        </Button>
      </div>
    </div>
  );
};

export default TeamCreate;
